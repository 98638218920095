import { InteractionConfig } from './types';
import { motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	if (props.shouldPlay) {
		// play videos, sounds, etc
	}

	return (
		<div className="relative font-londrina w-screen h-screen border-2 grid place-items-center">
			<video src="/smoke.webm" autoPlay className="absolute w-full h-full" />
			<audio src="/poof.mp3" autoPlay />
			<motion.div
				initial={{ opacity: 0, y: -10 }}
				animate={{
					opacity: 1,
					y: [
						-9, // Start position
						-10, // Start position
						0, // Goes down
						-4, // Back up
					],
				}}
				transition={{
					delay: 0,
					ease: 'easeInOut',
					opacity: {
						duration: 0.2,
					},
					y: {
						duration: 0.3,
						yoyo: 0, // Loop the animation forever
					},
				}}
				className="bg-[#FDF9E6] border-[6px] border-[#0770B7] h-[130px] mb-[30px] min-w-[300px] max-w-[500px] flex items-start justify-start gap-4 text-center text-white relative"
			>
				<div className="flex gap-1 ml-3 pt-5">
					<img alt="tangia" className="max-w-[20px] h-full mt-[6px]" src="/tangia.png" />
					<p className="text-[#0770B7] text-2xl font-medium">Secret:</p>
				</div>
				<p className="text-[#FD7043] text-[65px] mr-4 font-bold pb-4 grow self-center">{props.config.code}</p>
				<div className="w-full absolute h-[4px] bg-[#0770B7] bottom-0" />
				<div className="w-full absolute h-[8px] bg-[#6CD7DA] bottom-[4px]" />
				<div className="w-full absolute h-[8px] bg-[#99F5F7] bottom-[12px]" />
			</motion.div>
		</div>
	);
}
