//TODO: update with yours
export interface InteractionConfig {
	timeout?: number; // time after which the interaction gets hidden - in ms
	code: string;
}

//TODO: update with yours
export function validateConfig(c: InteractionConfig): boolean {
	if (!c) {
		return false;
	}
	if (!c.timeout) {
		c.timeout = Number(new URLSearchParams(window.location.search).get('timeout') || '10000');
	}
	return true;
}
